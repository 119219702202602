import { createFormData, isEmpty, isNotEmpty } from '../js-helpers/helpers'
import axiosInstance, { axiosAuth } from './axios/axiosConfig'

//TODO need decompose

export const setPreparedAxiosParams = params => {
    const preparedParams = new URLSearchParams()

    Object.entries(params).forEach(([key, value]) => {
        if (isEmpty(value)) {
            return
        }

        if (Array.isArray(value)) {
            value.forEach(item => isNotEmpty(item) && preparedParams.append(key, item))
            return
        }

        // if (key === 'ordering')
        // 	value = getExtraFullNameOrdering(value)

        preparedParams.append(key, value)
    })

    return {params: preparedParams}
}

export const apiLogin = {
    login: ({email, password}) => axiosAuth.post(`/accounts/auth/`, {email, password}).then(res => res.data),

    refreshToken: refreshToken =>
        axiosAuth.post(`/accounts/auth/refresh/`, {
            refresh: refreshToken,
        }).then(res => res.data),
}

export const apiPassword = {
    resetByEmail: data => axiosInstance.post('/accounts/password-reset/', data),
    createNewPassword: data => axiosInstance.post('/accounts/password-reset/confirm/', data),
    checkToken: data => axiosInstance.post('/accounts/password-reset/validate-token/', data),

    editCurrentUserPassword: data => axiosInstance.patch('/accounts/password/', data),
}

export const apiFiles = {
    createImage: data => axiosInstance.post(`/media/images/`, data, {_isFile: true}),
    deleteImage: id => axiosInstance.delete(`/media/images/${id}/`, {_isFile: true}),

    getFiles: (folder, page, page_size, search) =>
        axiosInstance(
            '/media/files/',
            setPreparedAxiosParams({
                folder,
                page,
                page_size,
                search,
            }),
        ),

    getExternalFiles: (id, key, page, page_size) =>
        axiosInstance(
            `/folder-files/${id}/auth/${key}`,
            setPreparedAxiosParams({
                page,
                page_size,
            }),
        ),

    createFile: data => axiosInstance.post(`/media/files/`, data, {_isFile: true}),

    createFileWithFolder: ({file, folder}) =>
        axiosInstance.post(`/media/files/`, createFormData({file, folder}), {_isFile: true}),
    deleteFile: id => axiosInstance.delete(`/media/files/${id}/`),

    getFolders: (page, page_size, parent, search, parent__isnull) =>
        axiosInstance(
            `/folders/`,
            setPreparedAxiosParams({
                page,
                page_size,
                parent,
                search,
                parent__isnull,
            }),
        ),

    getExternalFolders: (id, key, page, page_size) =>
        axiosInstance(
            `/folders/${id}/auth/${key}`,
            setPreparedAxiosParams({
                page,
                page_size,
            }),
        ),
    createFolder: function ({
                                title,
                                parent,
                                is_editable = false,
                                is_deletable = false,
                                is_external_linked = false,
                            }) {
        return axiosInstance.post(`/folders/`, arguments[0])
    },
    getFolder: id => axiosInstance(`/folders/${id}/`),
    editFolder: (id, data) => axiosInstance.patch(`/folders/${id}/`, data),
    deleteFolder: id => axiosInstance.delete(`/folders/${id}/`),
}

export const apiProjects = {
    getProjects: (search, ordering, parent__isnull, parent) =>
        axiosInstance(
            `/marks/projects/`,
            setPreparedAxiosParams({search, ordering, parent__isnull, parent}),
        ),

    createProject: data => axiosInstance.post(`/marks/projects/`, data),

    getOneProject: id => axiosInstance(`/marks/projects/${id}/`),

    editProject: (id, data) => axiosInstance.patch(`/marks/projects/${id}/`, data),

    deleteProject: id => axiosInstance.delete(`/marks/projects/${id}/`),

    getProjectComments: (project, page, page_size) =>
        axiosInstance('/marks/project-comments/', setPreparedAxiosParams({project, page, page_size})),

    postProjectComments: data => axiosInstance.post('/marks/project-comments/', data),

    deleteProjectComment: id => axiosInstance.delete(`/marks/project-comments/${id}`),
}

export const apiSources = {
    getSourcesList: (page, page_size = 12, ordering, search) =>
        axiosInstance(`/source/`, setPreparedAxiosParams({page, page_size, ordering, search})),

    createSource: data => axiosInstance.post(`/source/`, data),

    getOneSource: id => axiosInstance(`/source/${id}/`),

    editSource: (id, data) => axiosInstance.patch(`/source/${id}/`, data),

    deleteSource: id => axiosInstance.delete(`/source/${id}/`),

    createSourceSynonym: data => axiosInstance.post('/source/synonym/', data),

    deleteSourceSynonym: id => axiosInstance.delete(`/source/synonym/${id}/`),

    getSourceComments: (sourceId, page, page_size, search) =>
        axiosInstance(
            `/source/comments/`,
            setPreparedAxiosParams({source: sourceId, page, page_size, search}),
        ),

    postSourceComments: data => axiosInstance.post(`/source/comments/`, data),

    deleteSourceComment: id => axiosInstance.delete(`/source/comments/${id}`),

    getDistributionForm: (page, page_size = 12) =>
        axiosInstance(`/source/distribution-form/`, setPreparedAxiosParams({page, page_size})),

    postDistributionForm: data => axiosInstance.post(`/source/distribution-form/`, data),

    deleteDistributionForm: id => axiosInstance.delete(`/source/distribution-form/${id}/`),

    getDistributionLangs: (page, page_size = 12) =>
        axiosInstance(`/source/distribution-language/`, setPreparedAxiosParams({page, page_size})),

    postDistributionLang: data => axiosInstance.post(`/source/distribution-language/`, data),

    deleteDistributionLang: id => axiosInstance.delete(`/source/distribution-language/${id}/`),

    getFrequencyOfOutput: (page, page_size = 12) =>
        axiosInstance(`/source/frequency-of-output/`, setPreparedAxiosParams({page, page_size})),

    postFrequencyOfOutput: data => axiosInstance.post(`/source/frequency-of-output/`, data),

    deleteFrequencyOfOutput: id => axiosInstance.delete(`/source/frequency-of-output/${id}/`),

    getTargetGroup: (page, page_size = 12) =>
        axiosInstance(`/source/target-group/`, setPreparedAxiosParams({page, page_size})),

    postTargetGroup: data => axiosInstance.post(`/source/target-group/`, data),

    deleteTargetGroup: id => axiosInstance.delete(`/source/target-group/${id}/`),
}

export const apiPools = {
    getSourcesPools: (search, parent__isnull, parent) =>
        axiosInstance(`/source/pools/`, setPreparedAxiosParams({search, parent__isnull, parent})),

    createPool: data => axiosInstance.post(`/source/pools/`, data),

    getOnePool: id => axiosInstance(`/source/pools/${id}/`),

    editPool: (id, data) => axiosInstance.patch(`/source/pools/${id}/`, data),

    deletePool: id => axiosInstance.delete(`/source/pools/${id}/`),
}

export const apiTypes = {
    getSourcesTypes: (search, parent__isnull, parent) =>
        axiosInstance(`/source/types/`, setPreparedAxiosParams({search, parent__isnull, parent})),

    createType: data => axiosInstance.post(`/source/types/`, data),

    getOneType: id => axiosInstance(`/source/types/${id}/`),

    editType: (id, data) => axiosInstance.patch(`/source/types/${id}/`, data),

    deleteType: id => axiosInstance.delete(`/source/types/${id}/`),
}

export const apiReports={
    getReportTypes: id=>axiosInstance(`/report_type/${id}/children/`)
}

export const apiGeographySources = {
    getGeographySources: (search, ordering, parent__isnull, parent) =>
        axiosInstance(
            `/marks/geography-sources/`,
            setPreparedAxiosParams(
                search ? {search, ordering} : {search, ordering, parent__isnull, parent},
            ),
        ),

    createGeographySource: data => axiosInstance.post(`/marks/geography-sources/`, data),

    getOneGeographySource: id => axiosInstance(`/marks/geography-sources/${id}/`),

    editGeographySource: (id, data) => axiosInstance.patch(`/marks/geography-sources/${id}/`, data),

    deleteGeographySource: id => axiosInstance.delete(`/marks/geography-sources/${id}/`),

    createSynonym: data => axiosInstance.post(`/marks/geography-sources/synonyms/`, data),

    deleteSynonym: id => axiosInstance.delete(`/marks/geography-sources/synonyms/${id}/`),
}

export const apiGeographyMentions = {
    getGeographyMentions: (search, ordering, parent__isnull, parent) =>
        axiosInstance(
            `/marks/geography-mentions/`,
            setPreparedAxiosParams(
                search ? {search, ordering} : {search, ordering, parent__isnull, parent},
            ),
        ),

    createGeographyMention: data => axiosInstance.post(`/marks/geography-mentions/`, data),

    getOneGeographyMention: id => axiosInstance(`/marks/geography-mentions/${id}/`),

    editGeographyMention: (id, data) => axiosInstance.patch(`/marks/geography-mentions/${id}/`, data),

    deleteGeographyMention: id => axiosInstance.delete(`/marks/geography-mentions/${id}/`),

    createSynonym: data => axiosInstance.post(`/marks/geography-mentions/synonyms/`, data),

    deleteSynonym: id => axiosInstance.delete(`/marks/geography-mentions/synonyms/${id}/`),
}

export const apiThemes = {
  getThemesList: (search, ordering, parent__isnull, parent, page_size = 1000) =>
    axiosInstance(
      '/marks/themes/',
      setPreparedAxiosParams(
        search
          ? { search, ordering, page_size }
          : { search, ordering, parent__isnull, parent, page_size },
      ),
    ).then(data => data?.results),

  createTheme: data => axiosInstance.post(`/marks/themes/`, data),

  getOneTheme: id => axiosInstance(`/marks/themes/${id}/`),

  editTheme: (id, data) => axiosInstance.patch(`/marks/themes/${id}/`, data),

  deleteTheme: id => axiosInstance.delete(`/marks/themes/${id}/`),
}
export const apiOrganizations = {
    getOrganizationsList: (search, ordering, parent__isnull, parent) =>
        axiosInstance(
            `/marks/organizations/`,
            setPreparedAxiosParams({search, ordering, parent__isnull, parent}),
        ),

    createOrganization: data => axiosInstance.post(`/marks/organizations/`, data),

    getOneOrganization: id => axiosInstance(`/marks/organizations/${id}/`),

    editOrganization: (id, data) => axiosInstance.patch(`/marks/organizations/${id}/`, data),

    deleteOrganization: id => axiosInstance.delete(`/marks/organizations/${id}/`),

    createSynonym: data => axiosInstance.post(`/marks/organizations/synonyms/`, data),

    deleteSynonym: id => axiosInstance.delete(`/marks/organizations/synonyms/${id}/`),
}

export const apiPersons = {
    getPersonsList: (page, page_size = 12, ordering, search, is_expert) =>
        axiosInstance(
            `/marks/persons/`,
            setPreparedAxiosParams({page, page_size, ordering, search, is_expert}),
        ),

    createPerson: data => axiosInstance.post(`/marks/persons/`, data),

    getOnePerson: id => axiosInstance(`/marks/persons/${id}/`),

    editPerson: (id, data) => axiosInstance.patch(`/marks/persons/${id}/`, data),

    deletePerson: id => axiosInstance.delete(`/marks/persons/${id}/`),

    createSynonym: data => axiosInstance.post(`/marks/person-synonyms/`, data),

    deleteSynonym: id => axiosInstance.delete(`/marks/person-synonyms/${id}/`),
}

export const apiBrands = {
    getBrandsList: (page, page_size = 12, ordering, search, organization) =>
        axiosInstance(
            `/marks/brands/`,
            setPreparedAxiosParams({
                page,
                page_size,
                ordering,
                search,
                organization,
            }),
        ),

    createBrand: data => axiosInstance.post(`/marks/brands/`, data),

    getOneBrand: id => axiosInstance(`/marks/brands/${id}/`),

    editBrand: (id, data) => axiosInstance.patch(`/marks/brands/${id}/`, data),

    deleteBrand: id => axiosInstance.delete(`/marks/brands/${id}/`),

    createSynonym: data => axiosInstance.post(`/marks/brands/synonyms/`, data),

    deleteSynonym: id => axiosInstance.delete(`/marks/brands/synonyms/${id}/`),
}

export const apiProducts = {
    getProductsList: (page, page_size = 12, ordering, search, brand, brand__organization) =>
        axiosInstance(
            `/marks/products/`,
            setPreparedAxiosParams({
                page,
                page_size,
                ordering,
                search,
                brand,
                brand__organization,
            }),
        ),

    createProduct: data => axiosInstance.post(`/marks/products/`, data),

    getOneProduct: id => axiosInstance(`/marks/products/${id}/`),

    editProduct: (id, data) => axiosInstance.patch(`/marks/products/${id}/`, data),

    deleteProduct: id => axiosInstance.delete(`/marks/products/${id}/`),

    createSynonym: data => axiosInstance.post(`/marks/products/synonyms/`, data),

    deleteSynonym: id => axiosInstance.delete(`/marks/products/synonyms/${id}/`),
}

export const apiUsers = {
    getFullProjectsList: () => axiosInstance(`/accounts/projects/`),
    getAdminModulesList: () => axiosInstance(`/accounts/admin-access/`),
    getClientModulesList: () => axiosInstance(`/accounts/client-access/`),

    getUsersList: (page, page_size = 12, ordering, search, is_admin) =>
        axiosInstance(
            `/accounts/`,
            setPreparedAxiosParams({page, page_size, ordering, search, is_admin}),
        ),

    createAdmin: data => axiosInstance.post(`/accounts/admins/`, data),

    getOneAdmin: id => axiosInstance(`/accounts/admins/${id}/`),

    editAdmin: (id, data) => axiosInstance.patch(`/accounts/admins/${id}/`, data),

    deleteAdmin: id => axiosInstance.delete(`/accounts/admins/${id}/`),

    createClient: data => axiosInstance.post(`/accounts/clients/`, data),

    getOneClient: id => axiosInstance(`/accounts/clients/${id}/`),

    editClient: (id, data) => axiosInstance.patch(`/accounts/clients/${id}/`, data),

    deleteClient: id => axiosInstance.delete(`/accounts/clients/${id}/`),

    getCurrentAdmin: () => axiosInstance('/accounts/admin/'),

    editCurrentAdmin: data => axiosInstance.patch('/accounts/admin/', data),

    getCurrentAdminAccess: () => axiosInstance('/accounts/admin/access/'),
}

export const apiColors = {
    getColorsList: () => axiosInstance(`/accounts/colors/`),
    editColor: (id, data) => axiosInstance.patch(`/accounts/colors/${id}/`, data),
}

export const apiIndexing = {
    getIndexingList: (page, page_size = 12, ordering, search) =>
        axiosInstance(`/indexing/`, setPreparedAxiosParams({page, page_size, ordering, search})),

    createIndexing: data => axiosInstance.post(`/indexing/`, data),

    getOneIndexing: id => axiosInstance(`/indexing/${id}/`),

    editIndexing: (id, data) => axiosInstance.patch(`/indexing/${id}/`, data),

    deleteIndexing: id => axiosInstance.delete(`/indexing/${id}/`),

    getArticlesByQuery: (id, page, page_size = 12) =>
        axiosInstance(`/indexing/${id}/search/`, setPreparedAxiosParams({page, page_size})),

    doIndexing: (id, page, page_size = 12) =>
        axiosInstance(`/indexing/${id}/indexing/`, setPreparedAxiosParams({page, page_size})),
}

export const apiManualIndexing = {
    getArticlesForIndexing: (page, page_size, ordering, list_pk) =>
        axiosInstance(
            `/article/filter/`,
            setPreparedAxiosParams({page, page_size, ordering, list_pk}),
        ),
    doManualIndexing: data => axiosInstance.post('/indexing/manual/', data),
}

export const apiSearchQuery = {
    getSearchQueryList: (page, page_size = 12, ordering, search) =>
        axiosInstance(
            `/search_queries/`,
            setPreparedAxiosParams({page, page_size, ordering, search}),
        ),

    createSearchQuery: data => axiosInstance.post(`/search_queries/`, data),

    getOneSearchQuery: id => axiosInstance(`/search_queries/${id}/`),

    editSearchQuery: (id, data) => axiosInstance.patch(`/search_queries/${id}/`, data),

    deleteSearchQuery: id => axiosInstance.delete(`/search_queries/${id}/`),

    getArticlesByQuery: (id, page, page_size = 12, ordering, search) =>
        axiosInstance(
            `/search_queries/${id}/search/`,
            setPreparedAxiosParams({page, page_size, ordering, search}),
        ),

    getArticlesByQueryAllResult: (id, page, page_size = 12, ordering, search) =>
        axiosInstance(
            `/search_queries/${id}/search/`,
            setPreparedAxiosParams({page, page_size, ordering, search}),
        ),
}

export const apiTonality = {
    getTonalityData: (id, tonalities_keys) =>
        axiosInstance(`/tonality/${id}/`, setPreparedAxiosParams({tonalities_keys})),

    getPersonsByOrganizationTonality: data => axiosInstance.post(`/tonality/persons/`, data),

    getProductsByBrandTonality: data => axiosInstance.post(`/tonality/products/`, data),
}

export const apiMarks = {
    getMarks: (page, page_size, search) =>
        axiosInstance('/article/marks/', setPreparedAxiosParams({page, page_size, search})),

    createMark: data => axiosInstance.post(`/article/marks/`, data),

    deleteMark: id => axiosInstance.delete(`/article/marks/${id}/`),
}

export const apiMonitoring = {
    getMonitoringList: (
        search,
        ordering,
        parent__isnull,
        parent,
        parent_project,
        parent_project__isnull,
    ) =>
        axiosInstance(
            `/monitoring/`,
            setPreparedAxiosParams({
                search,
                ordering,
                parent__isnull,
                parent,
                parent_project,
                parent_project__isnull,
            }),
        ),

    createMonitoring: data => axiosInstance.post(`/monitoring/`, data),

    getOneMonitoring: id => axiosInstance(`/monitoring/${id}/`),

    editMonitoring: (id, data) => axiosInstance.patch(`/monitoring/${id}/`, data),

    deleteMonitoring: id => axiosInstance.delete(`/monitoring/${id}/`),
}

export const apiIndexingMonitoring = {
    getIndexingMonitoringList: (page, page_size = 12, ordering, search) =>
        axiosInstance(
            `/monitoring-indexing/`,
            setPreparedAxiosParams({page, page_size, ordering, search}),
        ),

    createIndexingMonitoring: data => axiosInstance.post(`/monitoring-indexing/`, data),

    getOneIndexingMonitoring: id => axiosInstance(`/monitoring-indexing/${id}/`),

    editIndexingMonitoring: (id, data) => axiosInstance.patch(`/monitoring-indexing/${id}/`, data),

    deleteIndexingMonitoring: id => axiosInstance.delete(`/monitoring-indexing/${id}/`),

    getArticlesByQuery: (id, page, page_size = 12) =>
        axiosInstance(
            `/monitoring-indexing/${id}/search/`,
            setPreparedAxiosParams({page, page_size}),
        ),

    doIndexing: id => axiosInstance(`/monitoring-indexing/${id}/indexing/`),
}

export const apiParagraphs = {
    createParagraph: data => axiosInstance.post('/paragraphs/', data),

    editParagraph: (id, data) => axiosInstance.patch(`/paragraphs/${id}/`, data),

    deleteParagraph: id => axiosInstance.delete(`/paragraphs/${id}/`),
}

export const apiRegularMonitoring = {
    createRegularMonitoring: data => axiosInstance.post('/regular-monitoring/', data),

    getOneRegularMonitoring: id => axiosInstance(`/regular-monitoring/${id}/`),

    editRegularMonitoring: (id, data) => axiosInstance.patch(`/regular-monitoring/${id}/`, data),

    deleteRegularMonitoring: id => axiosInstance.delete(`/regular-monitoring/${id}/`),

    editRubricOfRegularMonitoring: (id, data) =>
        axiosInstance.patch(`/regular-monitoring/rubric/${id}/`, data),

    deleteRubricOfRegularMonitoring: id => axiosInstance.delete(`/regular-monitoring/rubric/${id}/`),

    getKeyEventsList: id => axiosInstance(`/regular-monitoring/${id}/key-events/`),

    deleteKeyEvent: id => axiosInstance.delete(`/regular-monitoring/key-events/${id}/`),
}

export const apiPosition = {
    setKeyEventsPosition: data => axiosInstance.post('/key-events/position/', data),

    setRubricsPosition: data => axiosInstance.post('/rubrics/position/', data),
}

export const apiStatistic = {
    getGraphicsList: (page, page_size, ordering, search) =>
        axiosInstance('/stats/graph/', setPreparedAxiosParams({page, page_size, ordering, search})),

    getGraphicTypes: (page, page_size) =>
        axiosInstance('/stats/type_graph/', setPreparedAxiosParams({page, page_size})),

    postLimitSearchQueries: data => axiosInstance.post('/stats/limit_search_queries/', data),

    patchLimitSearchQueries: (id, data) =>
        axiosInstance.patch(`/stats/limit_search_queries/${id}/`, data),

    postAxisX: data => axiosInstance.post('/stats/axis_x/', data),

    patchAxisX: (id, data) => axiosInstance.patch(`/stats/axis_x/${id}/`, data),

    postAxisY: data => axiosInstance.post('/stats/axis_y/', data),

    patchAxisY: (id, data) => axiosInstance.patch(`/stats/axis_y/${id}/`, data),

    postGraph: data => axiosInstance.post('/stats/graph/', data),

    getOneGraph: id => axiosInstance(`/stats/graph/${id}/`),

    pathOneGraph: (id, data) => axiosInstance.patch(`/stats/graph/${id}/`, data),
    deleteOneGraph: id => axiosInstance.delete(`/stats/graph/${id}/`),

    getDiffTypeGraphDisplay: (id, page, page_size, pub_time__gt, pub_time__lt) =>
        axiosInstance(
            `/stats/graph/${id}/display/`,
            setPreparedAxiosParams({page, page_size, pub_time__gt, pub_time__lt}),
        ),
    getOneTypeGraphDisplay: (id, page, page_size, pub_time__gt, pub_time__lt) =>
        axiosInstance(
            `/stats/graph_one_type_categories/${id}/display/`,
            setPreparedAxiosParams({page, page_size, pub_time__gt, pub_time__lt}),
        ),
    getDiagramDisplay: (id, page, page_size, pub_time__gt, pub_time__lt) =>
        axiosInstance(
            `/stats/pie_chart/${id}/display/`,
            setPreparedAxiosParams({page, page_size, pub_time__gt, pub_time__lt}),
        ),

    getBarChartDisplay: (id, page, page_size, pub_time__gt, pub_time__lt) =>
        axiosInstance(
            `/stats/bar_chart/${id}/display/`,
            setPreparedAxiosParams({page, page_size, pub_time__gt, pub_time__lt}),
        ),

    getDiffTypeGraphLegend: id => axiosInstance(`/stats/graph/${id}/legend_graph/`),

    getOneTypeGraphLegend: id =>
        axiosInstance(`/stats/graph_one_type_categories/${id}/legend_graph/`),

    getDiagramLegend: id => axiosInstance(`/stats/pie_chart/${id}/legend_graph/`),

    getBarChartLegend: id => axiosInstance(`/stats/bar_chart/${id}/legend_graph/`),
}
